import React, { lazy, Component, Suspense } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
// import MapProvider from './admin/common/contexts/MapContext/MapProvider';
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

import './styles/site/app.scss';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
// import MobileRefundPolicy from './site/pages/mobile/MobileRefundPolicy';
// import AppLanding from './site/pages/newHome/AppLanding';
import { hasLoggedIn } from './utils/authUtils';
import { PersistGate } from 'redux-persist/integration/react';
import { SET_AUTH } from './redux/actionTypes';
import { persistor, store } from './redux/store';
import { Spinner } from 'reactstrap';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

import { Navigate } from "react-router-dom";


const Home = lazy(() => import('./site/pages/Home'));
const Login = lazy(() => import('./site/pages/Login'));
const Logout = lazy(() => import('./site/pages/Logout'));

////Admin 

function RequireAuth({ children }) {
  // const { authed } = useAuth();

  return hasLoggedIn() ? children : <Navigate to="/login" replace />;
}
const loading = (
  <div className={'loader'}>
    <Spinner
      type={'grow'}
      color={'primary'}
      style={{ width: '5rem', height: '5rem' }}>
      {''}
    </Spinner>
  </div>
);
class App extends Component {
  render() {
    return (
      <React.StrictMode>
        <Provider store={store}>
          <ReduxToastr
            timeOut={60}
            newestOnTop={false}
            preventDuplicates
            position="top-right"
            getState={(state) => state.toastr}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
          <PersistGate loading={loading} persistor={persistor}>
            <BrowserRouter>
              <Suspense fallback={loading}>
                <Routes>
                  <Route element={<Login />} path={`/login`} exact />
                  <Route element={<Logout />} path={`/logout`} exact />
                  {/* <Route element={<MobileRegister />} path={`/mobile-register`} exact /> */}





                  {/* <Route path="/" name="Home" element={<Home />} /> */}




                  <Route path="*" name="Admin" element={
                    <RequireAuth>
                      <DefaultLayout />
                    </RequireAuth>

                  } />



                </Routes>
              </Suspense>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </React.StrictMode>
    )
  }
}

export default App
