import { ActionType } from 'redux-promise-middleware';
import {
    ADMIN_FETCH_ALERTS,
    ADMIN_FETCH_CONTACTS,
    ADMIN_FETCH_PROPERTIES,
    ADMIN_FETCH_ROLES,
    ADMIN_FETCH_USERS,
    ADMIN_SHOW_SIDEBAR,
    ADMIN_FETCH_SETTLEMENT_DAILY_WORKS,
    ADMIN_FETCH_INTAKE_DAILY_WORKS,
    ADMIN_FETCH_INTAKE_CLIENTS,
    ADMIN_FETCH_CLIENT_EMERGENCY_CONTACTS,
    ADMIN_FETCH_CLIENT_MENTAL_HISTORIES,
    ADMIN_FETCH_MENTAL_DAILY_WORKS,
    ADMIN_FETCH_MENTAL_HISTORIY_NOTES,
    ADMIN_FETCH_MENTAL_DAILY_WORK_BY_ID,
    ADMIN_FETCH_SETTLEMENT_REPORT,
    ADMIN_FETCH_INTAKE_REPORT,
    ADMIN_FETCH_MANTAL_HEALTH_REPORT
} from '../actionTypes';

const initialState = {
    users: [],
    properties: [],
    contacts: [],
    roles: [],
    alerts: [],
    settlementDailyWorks: [],
    settlementDailyWorksPagination: {},
    intakeDailyWorks: [],
    intakeDailyWorksPagination: {},
    intakeClients: [],
    intakeClientsPagination: {},
    clientEmergencyContacts: [],
    clientEmergencyContactsPagination: {},
    sidebarShow: 'responsive',
    clientMentalHistories: [],
    clientMentalHistoriesPagination: {},
    mentalDailyWorks: [],
    mentalDailyWorksPagination: {},
    mentalHistoryNotes: [],
    mentalHistoryNotesPagination: {},
    reportData: [],
    top10Resp: [],
    groupResp: [],
    programResp: [],
    topNeedResp: [],
    monthlyResp: [],
    monthlyResp2: [],
    needsResp: [],
    ircResp: [],
    staffResp: [],
    tenantSumResp: [],
    topNeedMonthResp: [],
    officeService: [],
    ircRemarksResp: []
};

function adminReducer(state = initialState, action) {
    switch (action.type) {
        // Users
        case `${ADMIN_FETCH_USERS}_${ActionType.Pending}`:
            return {
                ...state,
                users: [],
            };
        case `${ADMIN_FETCH_MANTAL_HEALTH_REPORT}_${ActionType.Fulfilled}`:
            return {
                ...state,
                reportData: action.payload,
                top10Resp: action.payload.top10Resp[0],
                topNeedResp: action.payload.topNeedResp[0],
                groupResp: action.payload.groupResp[0],
                needsResp: action.payload.needsResp[0],
                staffResp: action.payload.staffResp[0],
                tenantSumResp: action.payload.tenantSumResp[0],
                topNeedMonthResp: action.payload.topNeedMonthResp[0]
            };
        case `${ADMIN_FETCH_INTAKE_REPORT}_${ActionType.Fulfilled}`:
            return {
                ...state,
                reportData: action.payload,
                monthlyResp: action.payload.officeResp[0],
                monthlyResp2: action.payload.officeResp2[0],
                top10Resp: action.payload.top10Resp[0],
                programResp: action.payload.programResp[0],
                needsResp: action.payload.needsResp[0],
                ircResp: action.payload.ircResp[0],
                officeService: action.payload.officeService[0],
                ircRemarksResp: action.payload.ircEntranceResp[0],
            };
        case `${ADMIN_FETCH_SETTLEMENT_REPORT}_${ActionType.Fulfilled}`:
            // console.log('..action.payload..' + JSON.stringify(action.payload));
            return {
                ...state,
                reportData: action.payload,
                top10Resp: action.payload.top10Resp[0],
                groupResp: action.payload.groupResp[0],
                needsResp: action.payload.needsResp[0],
                staffResp: action.payload.staffResp[0],
                tenantSumResp: action.payload.tenantSumResp[0],
                officeService: action.payload.officeService[0]
            };
        case `${ADMIN_FETCH_MENTAL_HISTORIY_NOTES}_${ActionType.Fulfilled}`:
            return {
                ...state,
                mentalHistoryNotes: action.payload.data,
                mentalHistoryNotesPagination: action.payload.meta.pagination
            };
        case `${ADMIN_FETCH_MENTAL_DAILY_WORKS}_${ActionType.Fulfilled}`:
            return {
                ...state,
                mentalDailyWorks: action.payload.data,
                mentalDailyWorksPagination: action.payload.meta.pagination
            };
        case `${ADMIN_FETCH_CLIENT_MENTAL_HISTORIES}_${ActionType.Fulfilled}`:
            return {
                ...state,
                clientMentalHistories: action.payload.data,
                clientMentalHistoriesPagination: action.payload.meta.pagination
            };
        case `${ADMIN_FETCH_CLIENT_EMERGENCY_CONTACTS}_${ActionType.Fulfilled}`:
            return {
                ...state,
                clientEmergencyContacts: action.payload.data,
                clientEmergencyContactsPagination: action.payload.meta.pagination
            };
        case `${ADMIN_FETCH_INTAKE_CLIENTS}_${ActionType.Fulfilled}`:
            return {
                ...state,
                intakeClients: action.payload.data,
                intakeClientsPagination: action.payload.meta.pagination
            };

        case `${ADMIN_FETCH_INTAKE_DAILY_WORKS}_${ActionType.Fulfilled}`:
            return {
                ...state,
                intakeDailyWorks: action.payload.data,
                intakeDailyWorksPagination: action.payload.meta.pagination
            };
        case `${ADMIN_FETCH_SETTLEMENT_DAILY_WORKS}_${ActionType.Fulfilled}`:
            // console.log('....ADMIN_FETCH_SETTLEMENT_DAILY_WORKS..' + JSON.stringify(action.payload));
            return {
                ...state,
                settlementDailyWorks: action.payload.data,
                // settlementDailyWorksPagination: action.payload.meta.pagination
            };
        case `${ADMIN_FETCH_USERS}_${ActionType.Fulfilled}`:
            return {
                ...state,
                users: action.payload,
            };
        // Properties
        case `${ADMIN_FETCH_PROPERTIES}_${ActionType.Pending}`:
            return {
                ...state,
                properties: [],
            };
        case `${ADMIN_FETCH_PROPERTIES}_${ActionType.Fulfilled}`:
            return {
                ...state,
                properties: action.payload,
            };
        // Contacts
        case `${ADMIN_FETCH_CONTACTS}_${ActionType.Pending}`:
            return {
                ...state,
                contacts: [],
            };
        case `${ADMIN_FETCH_CONTACTS}_${ActionType.Fulfilled}`:
            return {
                ...state,
                contacts: action.payload,
            };
        // Roles
        case `${ADMIN_FETCH_ROLES}_${ActionType.Fulfilled}`:
            return {
                ...state,
                roles: action.payload,
            };
        case `${ADMIN_FETCH_ROLES}_${ActionType.Rejected}`:
            return {
                ...state,
                roles: [],
            };
        // Alerts
        case `${ADMIN_FETCH_ALERTS}_${ActionType.Fulfilled}`:
            return {
                ...state,
                alerts: action.payload,
            };
        case `${ADMIN_FETCH_ALERTS}_${ActionType.Rejected}`:
            return {
                ...state,
                alerts: [],
            };

        // Others
        case ADMIN_SHOW_SIDEBAR:
            return {
                ...state,
                sidebarShow: action.payload,
            };
        default:
            return state;
    }
}

export default adminReducer;
